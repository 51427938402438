<div class="sidebar-container">
  <p-drawer [(visible)]="sidebarVisible" (onHide)="onSidebarHide()" [modal]="false" [dt]="drawer" >
    <ng-template pTemplate="headless" >
      <div class="flex align-items-center justify-content-between px-4 mt-3 flex-shrink-0">
        <div class="inline-flex align-items-center">
          <svg height="40" width="40" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 300 300" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" project-id="359077ae82994cda8621c2c62e5c3e48" export-id="e44616cfe11043f684b5f6b1c4d93021" cached="true"><ellipse rx="54.514671" ry="58.577877" transform="matrix(1.32298 0 0 1.265902 150 150)" paint-order="stroke markers fill" fill="rgba(255,255,255,0)" fill-rule="evenodd" stroke="#3730A3" stroke-width="25"/><ellipse rx="30" ry="30" transform="matrix(.778782 0 0 0.826184 120.626156 125.21448)" fill="#3730A3" stroke-width="0"/></svg>
          <span class="text-500-20px" id="logo-text" >Web Watch</span>
          <!-- <p-button [text]="true" class="ml-8" icon="pi pi-times" (onClick)="sidebarVisible=!sidebarVisible"></p-button> -->
        </div>
      </div>
      <div class="overflow-y-auto">
        <ul class="list-none p-3 m-0">
          <li>
              <a pRipple class="flex align-items-center cursor-pointer p-3 border-round text-700 highlight hover:surface-100 transition-duration-150 transition-colors p-ripple">
                <i class="pi pi-exclamation-triangle mr-4"></i>
                <span class="font-medium">Alerts</span>
              </a>
          </li>
          <li>
              <a routerLink="monitors"
                 pRipple class="flex align-items-center cursor-pointer p-3 border-round text-700 highlight hover:surface-100 transition-duration-150 transition-colors p-ripple">
                <i class="pi pi-bullseye mr-4"></i>
                <span class="font-medium">Monitors</span>
              </a>
          </li>
          <li>
              <a pRipple class="flex align-items-center cursor-pointer p-3 border-round text-700 highlight hover:surface-100 transition-duration-150 transition-colors p-ripple">
                <i class="pi pi-sitemap mr-4"></i>
                <span class="font-medium">Groups</span>
              </a>
          </li>
        </ul>
      </div>
  </ng-template>
  </p-drawer>
</div>
