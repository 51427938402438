<div #navbar class="navbar" [ngClass]="{'reduced': sidebarVisible}">
    <p-menubar >
        <ng-template pTemplate="start">
            <p-button [text]="true" (onClick)="toggleSidebar()" icon="pi pi-align-justify" />
        </ng-template>
        <ng-template pTemplate="end">
            <div class="flex align-items-center gap-5">
                <i class="pi pi-sun border-1 border-indigo-50 cursor-pointer p-2 border-round-3xl" (click)="toggleDarkTheme()"></i>
                <p-avatar image="https://primefaces.org/cdn/primeng/images/demo/avatar/amyelsner.png" shape="circle" />
            </div>
        </ng-template>
    </p-menubar>
</div>
