
import {Component, OnInit} from '@angular/core';
import {NgClass} from '@angular/common';
import {SidebarStateService} from '../../services/shared/sidebar/sidebar-state.service';
import {Subscription} from 'rxjs';
import {MenuModule} from 'primeng/menu';
import {MenubarModule} from 'primeng/menubar';
import {Button} from 'primeng/button';
import {CardModule} from 'primeng/card';
import {DropdownModule} from 'primeng/dropdown';
import {FormsModule} from '@angular/forms';
import {HttpMethods, HttpMonitorPayload, MonitorType, ScheduleIntervals} from '../../models/entities';
import {Router} from '@angular/router';
import {AccordionModule} from 'primeng/accordion';
import {ChipModule} from 'primeng/chip';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ToastModule} from 'primeng/toast';
import { CreateHttpMonitorComponent } from "../../components/create-monitor/create-http-monitor/create-http-monitor.component";

// noinspection TypeScriptValidateTypes
@Component({
  selector: 'app-create-monitor',
  standalone: true,
  imports: [
    NgClass,
    MenuModule,
    MenubarModule,
    Button,
    CardModule,
    DropdownModule,
    FormsModule,
    ChipModule,
    AccordionModule,
    ChipModule,
    ConfirmDialogModule,
    ToastModule,
    CreateHttpMonitorComponent
],
  templateUrl: './create-monitor.component.html',
  styleUrl: './create-monitor.component.scss',
  providers: [ConfirmationService, MessageService]
})
export class CreateMonitorComponent implements OnInit{
  sideBarVisible: boolean = false;
  private subscription!: Subscription;
  selectedMonitorType: MonitorType = {name: 'HTTPS', code: 'https'};
  monitorTypeOptions: MonitorType[] =  [
    {name: 'HTTPS', code: 'https'},
    {name: 'SSL', code: 'ssl'},
  ];

  constructor(private sidebarState: SidebarStateService, private router: Router,
              private confirmationService: ConfirmationService) {
  }

  ngOnInit(): void {
    this.subscription = this.sidebarState.sidebarVisible$.subscribe(
      visible => {
        this.sideBarVisible = visible;
      }
    );
  }

  navigateAway(){
    this.confirmationService.confirm({
      message: '<div class="flex flex-column justify-content-center align-items-center"><span>Are you sure?</span><span>Your changes to the form will be lost!</span></div>',
      icon: 'pi pi-info-circle',
      header: 'Confirmation',
      acceptIcon: "none",
      rejectIcon: "none",
      rejectButtonStyleClass:"p-button-text",
      accept: () => {
        this.backToMonitorPage();
      },
      reject: () => {},
      key: 'positionDialog'
    })
  }

  backToMonitorPage() {
    this.router.navigate(['/monitors']);
  }
}
