import { Routes } from '@angular/router';
import {MonitorsComponent} from './pages/monitors/monitors.component';
import {CreateMonitorComponent} from './pages/create-monitor/create-monitor.component';
import {LoginComponent} from './login/login/login.component';

export const routes: Routes = [
  {
    'path': 'monitors',
    'component': MonitorsComponent
  },
  {
    'path': 'monitors/create',
    'component': CreateMonitorComponent
  },
  {
    'path': 'login',
    'component': LoginComponent
  }
];
