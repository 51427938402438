<div class="monitor-listing-pane" [ngClass]="{'reduced': sidebarVisible}">
    <p-menubar class="menu">
        <ng-template pTemplate="start">
            <span class="text-500-20px">Monitors</span>
        </ng-template>
        <ng-template pTemplate="end">
            <p-button label="New Monitor" (onClick)="navigateToCreateMonitor()" icon="pi pi-plus"></p-button>
        </ng-template>
    </p-menubar>
    <p-menubar class="menu">
        <ng-template pTemplate="start" >
            <div class="flex align-items-center gap-3">
                <input type="text" class="border-round-xl w-16rem" placeholder="Search" pInputText />
                <span class="text-xl border-left-1 pl-4 ml-4 p-1">Quick Filter</span>
                <div class="card flex justify-content-center ml-4">
                  <p-select
                    class=""
                    [options]="groups"
                    [(ngModel)]="selectedGroup"
                    optionLabel="name"
                    placeholder="Group" />
                </div>
              <div class="card flex justify-content-center ml-4">
                <p-select
                  [options]="types"
                  [(ngModel)]="selectedType"
                  optionLabel="name"
                  placeholder="Type" />
              </div>
            </div>
        </ng-template>
    </p-menubar>
    <p-dataView #db [value]="monitors" class="overflow-x-auto">
      <ng-template pTemplate="list" let-monitors>
        @for(monitor of monitors; track monitor.id){
          <app-monitor-card [monitor]="monitor" [sidebarVisible]="sidebarVisible"></app-monitor-card>
        }
      </ng-template>
    </p-dataView>
</div>
