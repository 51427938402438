
export const environment = {
    production: false,
    debugMode: false,
    baseUri: 'https://api.carvia-test.org/watchdog-service',
    // baseUri: 'http://localhost:8080',
    keycloak: {
        keycloakUrl: 'https://sso.carvia-test.org/',
        realm: 'watchdog',
        clientId: 'watchdog-web'
    }
}
