import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { Menu } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { ButtonModule } from 'primeng/button';
import { AvatarModule } from 'primeng/avatar';
import { CommonModule } from '@angular/common';
import {SidebarStateService} from '../../services/shared/sidebar/sidebar-state.service';
import {Subscription} from 'rxjs';


@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [MenubarModule, ButtonModule, AvatarModule, CommonModule],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent implements OnInit{


    @Output() sidebarVisibleChange = new EventEmitter<boolean>();
    @Input() sidebarVisible: boolean = false;
    private subscription!: Subscription;
    constructor(private sidebarState: SidebarStateService) {
    }

    toggleSidebar() {
      this.sidebarVisible = !this.sidebarVisible;
      this.sidebarVisibleChange.emit(this.sidebarVisible);
      this.sidebarState.setSidebarVisible(this.sidebarVisible);
    }

  ngOnInit(): void {
      this.subscription = this.sidebarState.sidebarVisible$.subscribe(
        visible => {
          this.sidebarVisible = visible;
        }
      )
  }

  toggleDarkTheme() {
    const element = document.querySelector('html');
    element?.classList.toggle('watchdog-dark')
  }

}
