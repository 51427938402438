<div class="flex flex-column bg-gray-50  border-round-xl p-4 gap-6 pb-0 create-http-monitor-container">
        <p-card>
            <div class="flex flex-column">
                <div class="flex flex-column mt-0">
                    <span class="text-2xl mt-1 mb-1">URL*</span>
                    <input class="mt-1 mb-1" pInputText type="text" aria-describedby="url-help" [ngModel]="httpMonitorPayload.url"/>
                    <small id="url-help" class="mt-1">Enter the HTTP or HTTPS address to monitor</small>
                </div>
                <div class="flex flex-column mt-2">
                    <span class="text-2xl mt-1 mb-1">Monitor Name*</span>
                    <input pInputText class="mt-1" type="text" [ngModel]="httpMonitorPayload.label"/>
                </div>
            </div>
        </p-card> 
        <p-card>
            <div class="flex flex-column bg-gray-50  border-gray-100 border-round-xl p-4">
              <div class="flex-column flex">
                <span class="text-2xl">Check Frequency*</span>
                <div class="flex inline-flex gap-4 mt-3">
                  @for (interval of intervals; track interval) {
                    <div class="inline-flex flex-row gap-2">
                      <p-button severity="primary" [text]="true" [raised]="true" [rounded]="true" label="{{interval}}"></p-button>
                    </div>
                  }
                </div>
              </div>
              <div class="flex flex-column mt-5">
                <span class="text-2xl">Monitor Group</span>
                <div class="mt-3">
                  <p-dropdown
                    [options]="groups"
                    [(ngModel)]="selectedGroup"
                    placeholder="Select a Group"
                    optionLabel="name"
                  />
                </div>
              </div>
            </div>
        </p-card>
      
      <!--    TODO: complete accordion-->
        <p-accordion >
            <p-accordionTab header="Advanced Settings" class="text-2xl">
              <div class="flex flex-column bg-gray-50 border-1 border-gray-100 border-round-xl p-4">
                <div class="flex-column flex">
                  <span class="text-2xl">Up Http Status Codes</span>
                    <div class="card p-fluid mt-2">
                        <p-chips [(ngModel)]="values" />
                    </div>
                </div>
                <div class="flex flex-column border-1 mt-5">
                    <div class="flex flex-row gap-6 border-1">
                        <div class="flex justify-content-start">
                            <span class="text-2xl">Auth Type</span>
                        </div>
                        <div class="flex justify-content-start">
                            <span class="text-2xl">Auth Credentials</span>
                        </div>
                    </div>
                </div>
              </div>
            </p-accordionTab>
        </p-accordion>
</div> 