<div class="create-monitor-container flex flex-column gap-2" [ngClass]="{'reduced': sideBarVisible}" >
  <div class="flex flex-column">
    <p-menubar class="menu" >
      <ng-template pTemplate="start">
        <p-toast />
        <p-confirmDialog
          key="positionDialog"
          rejectButtonStyleClass="p-button-outlined" />
        <div pButton text="true"  (click)="navigateAway()" class="flex flex-row gap-2 align-items-center text-gray-500 justify-content-center p-2 pl-0 hover:text-gray-700 cursor-pointer">
          <i class="pi pi-arrow-left text-xl"></i>
          <span class="text-xl mb-1">go back</span>
        </div>
      </ng-template>
    </p-menubar>
    <p-menubar class="menu">
      <ng-template pTemplate="start">
        <span class="text-4xl">Create Monitor</span>
      </ng-template>
      <ng-template pTemplate="end">
        <p-button label="Save Monitor" class="text-black-alpha-90"></p-button>
      </ng-template>
    </p-menubar>
  </div>

  <div>
    <p-card>
      <div class="flex flex-column bg-gray-50 border-gray-100 border-round-xl p-4">
        <div>
          <span class="text-2xl">Monitor Type*</span>
          <p-dropdown
            [options]="monitorTypeOptions"
            [(ngModel)]="selectedMonitorType"
            optionLabel="name"
            placeholder="Select Monitor Type" />
        </div>

        
      </div>
    </p-card>
    
  </div>
  <div class="overflow-x-auto">
    @switch (selectedMonitorType.name) {
      @case ('HTTPS') {
          <app-create-http-monitor/>
      }
      @case ('SSL') {

      }
    }
  </div>
</div>


