import { Component, OnInit } from '@angular/core';
import { CardModule } from 'primeng/card';
import { HttpMethods, HttpMonitorPayload, ScheduleIntervals } from '../../../models/entities';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChipModule } from 'primeng/chip';
import { AccordionModule } from 'primeng/accordion';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-create-http-monitor',
  standalone: true,
  imports: [ChipModule,CardModule, FormsModule, ChipModule, AccordionModule, DropdownModule, ButtonModule, InputTextModule, FormsModule, ReactiveFormsModule],
  templateUrl: './create-http-monitor.component.html',
  styleUrl: './create-http-monitor.component.scss'
})
export class CreateHttpMonitorComponent implements OnInit{
  selectedGroup: any;
  groups: {}[] = [{name:'CarviaTech', id:0}, {name:'Dodda', id:1}, {name:'Survey Of India', id:2}];
  httpMonitorPayload: HttpMonitorPayload = {
    label: '',
    url: '',
    scheduleInterval: ScheduleIntervals.FIVE_MINUTES,
    expectedResponse: 200,
    disableScheduler: false,
    httpMethod: HttpMethods.GET,
  };
  intervals: string[] = Object.values(ScheduleIntervals);
  values: string[] = [];

  ngOnInit(): void {
  }

}
