import {Component, OnInit} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {SidebarComponent} from './components/sidebar/sidebar.component';
import {Button} from 'primeng/button';
import { NavbarComponent } from "./components/navbar/navbar.component";
import { MonitorsComponent } from "./pages/monitors/monitors.component";
import {KeycloakProfile} from 'keycloak-js';
import {KeycloakService} from 'keycloak-angular';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, SidebarComponent, Button, NavbarComponent, MonitorsComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit{
  title = 'watch-dog-web';
  public isLoggedIn = false;
  public userProfile: KeycloakProfile | null = null;

  constructor(private readonly keycloakService: KeycloakService) {
  }

  async ngOnInit() {
    this.isLoggedIn = await this.keycloakService.isLoggedIn();
    type userRoles = Array<{id:number, text:string}>
    if (this.isLoggedIn) {
      this.userProfile = await this.keycloakService.loadUserProfile();
    }
  }

  public login(){
    this.keycloakService.login();
  }

  public logout(){
    this.keycloakService.logout();
  }

}
