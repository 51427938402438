import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, EventEmitter, inject, Component, ChangeDetectionStrategy, ViewEncapsulation, Input, Output, HostBinding, NgModule } from '@angular/core';
import { SharedModule } from 'primeng/api';
import { BaseComponent } from 'primeng/basecomponent';
import { BaseStyle } from 'primeng/base';
const theme = ({
  dt
}) => `
.p-avatar {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: ${dt('avatar.width')};
    height: ${dt('avatar.height')};
    font-size: ${dt('avatar.font.size')};
    color: ${dt('avatar.color')};
    background: ${dt('avatar.background')};
    border-radius: ${dt('avatar.border.radius')};
}

.p-avatar-image {
    background: transparent;
}

.p-avatar-circle {
    border-radius: 50%;
}

.p-avatar-circle img {
    border-radius: 50%;
}

.p-avatar-icon {
    font-size: ${dt('avatar.icon.size')};
    width: ${dt('avatar.icon.size')};
    height: ${dt('avatar.icon.size')};
}

.p-avatar img {
    width: 100%;
    height: 100%;
}

.p-avatar-lg {
    width: ${dt('avatar.lg.width')};
    height: ${dt('avatar.lg.width')};
    font-size: ${dt('avatar.lg.font.size')};
}

.p-avatar-lg .p-avatar-icon {
    font-size: ${dt('avatar.lg.icon.size')};
    width: ${dt('avatar.lg.icon.size')};
    height: ${dt('avatar.lg.icon.size')};
}

.p-avatar-xl {
    width: ${dt('avatar.xl.width')};
    height: ${dt('avatar.xl.width')};
    font-size: ${dt('avatar.xl.font.size')};
}

.p-avatar-xl .p-avatar-icon {
    font-size: ${dt('avatar.xl.font.size')};
}

.p-avatar-group {
    display: flex;
    align-items: center;
}

.p-avatar-group .p-avatar + .p-avatar {
    margin-inline-start: ${dt('avatar.group.offset')};
}

.p-avatar-group .p-avatar {
    border: 2px solid ${dt('avatar.group.border.color')};
}

.p-avatar-group .p-avatar-lg + .p-avatar-lg {
    margin-inline-start: ${dt('avatar.lg.group.offset')};
}

.p-avatar-group .p-avatar-xl + .p-avatar-xl {
    margin-inline-start: ${dt('avatar.xl.group.offset')};
}
`;
const classes = {
  root: ({
    props
  }) => ['p-avatar p-component', {
    'p-avatar-image': props.image != null,
    'p-avatar-circle': props.shape === 'circle',
    'p-avatar-lg': props.size === 'large',
    'p-avatar-xl': props.size === 'xlarge'
  }],
  label: 'p-avatar-label',
  icon: 'p-avatar-icon'
};
class AvatarStyle extends BaseStyle {
  name = 'avatar';
  theme = theme;
  classes = classes;
  static ɵfac = /*@__PURE__*/(() => {
    let ɵAvatarStyle_BaseFactory;
    return function AvatarStyle_Factory(__ngFactoryType__) {
      return (ɵAvatarStyle_BaseFactory || (ɵAvatarStyle_BaseFactory = i0.ɵɵgetInheritedFactory(AvatarStyle)))(__ngFactoryType__ || AvatarStyle);
    };
  })();
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: AvatarStyle,
    factory: AvatarStyle.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AvatarStyle, [{
    type: Injectable
  }], null, null);
})();
/**
 *
 * Avatar represents people using icons, labels and images.
 *
 * - [Live Demo](https://primeng.org/avatar)
 *
 * @module avatarstyle
 *
 */
var AvatarClasses;
(function (AvatarClasses) {
  /**
   * Class name of the root element
   */
  AvatarClasses["root"] = "p-avatar";
  /**
   * Class name of the label element
   */
  AvatarClasses["label"] = "p-avatar-label";
  /**
   * Class name of the icon element
   */
  AvatarClasses["icon"] = "p-avatar-icon";
})(AvatarClasses || (AvatarClasses = {}));
const _c0 = ["*"];
function Avatar_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 3);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.label);
  }
}
function Avatar_ng_template_2_span_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 5);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵclassMap(ctx_r0.icon);
    i0.ɵɵproperty("ngClass", "p-avatar-icon");
  }
}
function Avatar_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Avatar_ng_template_2_span_0_Template, 1, 3, "span", 4);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    const imageTemplate_r2 = i0.ɵɵreference(5);
    i0.ɵɵproperty("ngIf", ctx_r0.icon)("ngIfElse", imageTemplate_r2);
  }
}
function Avatar_ng_template_4_img_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "img", 7);
    i0.ɵɵlistener("error", function Avatar_ng_template_4_img_0_Template_img_error_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r0 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r0.imageError($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("src", ctx_r0.image, i0.ɵɵsanitizeUrl);
    i0.ɵɵattribute("aria-label", ctx_r0.ariaLabel);
  }
}
function Avatar_ng_template_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Avatar_ng_template_4_img_0_Template, 1, 2, "img", 6);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngIf", ctx_r0.image);
  }
}
/**
 * Avatar represents people using icons, labels and images.
 * @group Components
 */
class Avatar extends BaseComponent {
  /**
   * Defines the text to display.
   * @group Props
   */
  label;
  /**
   * Defines the icon to display.
   * @group Props
   */
  icon;
  /**
   * Defines the image to display.
   * @group Props
   */
  image;
  /**
   * Size of the element.
   * @group Props
   */
  size = 'normal';
  /**
   * Shape of the element.
   * @group Props
   */
  shape = 'square';
  /**
   * Inline style of the element.
   * @group Props
   */
  style;
  /**
   * Class of the element.
   * @group Props
   */
  styleClass;
  /**
   * Establishes a string value that labels the component.
   * @group Props
   */
  ariaLabel;
  /**
   * Establishes relationships between the component and label(s) where its value should be one or more element IDs.
   * @group Props
   */
  ariaLabelledBy;
  /**
   * This event is triggered if an error occurs while loading an image file.
   * @param {Event} event - Browser event.
   * @group Emits
   */
  onImageError = new EventEmitter();
  _componentStyle = inject(AvatarStyle);
  imageError(event) {
    this.onImageError.emit(event);
  }
  get hostClass() {
    return this.styleClass;
  }
  static ɵfac = /*@__PURE__*/(() => {
    let ɵAvatar_BaseFactory;
    return function Avatar_Factory(__ngFactoryType__) {
      return (ɵAvatar_BaseFactory || (ɵAvatar_BaseFactory = i0.ɵɵgetInheritedFactory(Avatar)))(__ngFactoryType__ || Avatar);
    };
  })();
  static ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
    type: Avatar,
    selectors: [["p-avatar"]],
    hostVars: 19,
    hostBindings: function Avatar_HostBindings(rf, ctx) {
      if (rf & 2) {
        i0.ɵɵattribute("data-pc-name", "avatar")("aria-label", ctx.ariaLabel)("aria-labelledby", ctx.ariaLabelledBy);
        i0.ɵɵstyleMap(ctx.style);
        i0.ɵɵclassMap(ctx.hostClass);
        i0.ɵɵclassProp("p-avatar", true)("p-component", true)("p-avatar-circle", ctx.shape === "circle")("p-avatar-lg", ctx.size === "large")("p-avatar-xl", ctx.size === "xlarge")("p-avatar-image", ctx.image != null);
      }
    },
    inputs: {
      label: "label",
      icon: "icon",
      image: "image",
      size: "size",
      shape: "shape",
      style: "style",
      styleClass: "styleClass",
      ariaLabel: "ariaLabel",
      ariaLabelledBy: "ariaLabelledBy"
    },
    outputs: {
      onImageError: "onImageError"
    },
    standalone: true,
    features: [i0.ɵɵProvidersFeature([AvatarStyle]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
    ngContentSelectors: _c0,
    decls: 6,
    vars: 2,
    consts: [["iconTemplate", ""], ["imageTemplate", ""], ["class", "p-avatar-text", 4, "ngIf", "ngIfElse"], [1, "p-avatar-text"], [3, "class", "ngClass", 4, "ngIf", "ngIfElse"], [3, "ngClass"], [3, "src", "error", 4, "ngIf"], [3, "error", "src"]],
    template: function Avatar_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
        i0.ɵɵtemplate(1, Avatar_span_1_Template, 2, 1, "span", 2)(2, Avatar_ng_template_2_Template, 1, 2, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor)(4, Avatar_ng_template_4_Template, 1, 1, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
      }
      if (rf & 2) {
        const iconTemplate_r4 = i0.ɵɵreference(3);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.label)("ngIfElse", iconTemplate_r4);
      }
    },
    dependencies: [CommonModule, i1.NgClass, i1.NgIf, SharedModule],
    encapsulation: 2,
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Avatar, [{
    type: Component,
    args: [{
      selector: 'p-avatar',
      standalone: true,
      imports: [CommonModule, SharedModule],
      template: `
        <ng-content></ng-content>
        <span class="p-avatar-text" *ngIf="label; else iconTemplate">{{ label }}</span>
        <ng-template #iconTemplate><span [class]="icon" [ngClass]="'p-avatar-icon'" *ngIf="icon; else imageTemplate"></span></ng-template>
        <ng-template #imageTemplate> <img [src]="image" *ngIf="image" (error)="imageError($event)" [attr.aria-label]="ariaLabel" /></ng-template>
    `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      host: {
        '[class.p-avatar]': 'true',
        '[class.p-component]': 'true',
        '[class.p-avatar-circle]': 'shape === "circle"',
        '[class.p-avatar-lg]': 'size === "large"',
        '[class.p-avatar-xl]': 'size === "xlarge"',
        '[class.p-avatar-image]': 'image != null',
        '[attr.data-pc-name]': '"avatar"',
        '[attr.aria-label]': 'ariaLabel',
        '[attr.aria-labelledby]': 'ariaLabelledBy',
        '[style]': 'style'
      },
      providers: [AvatarStyle]
    }]
  }], null, {
    label: [{
      type: Input
    }],
    icon: [{
      type: Input
    }],
    image: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    shape: [{
      type: Input
    }],
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    ariaLabel: [{
      type: Input
    }],
    ariaLabelledBy: [{
      type: Input
    }],
    onImageError: [{
      type: Output
    }],
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassDebugInfo(Avatar, {
    className: "Avatar"
  });
})();
class AvatarModule {
  static ɵfac = function AvatarModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || AvatarModule)();
  };
  static ɵmod = /*@__PURE__*/i0.ɵɵdefineNgModule({
    type: AvatarModule
  });
  static ɵinj = /*@__PURE__*/i0.ɵɵdefineInjector({
    imports: [Avatar, SharedModule, SharedModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AvatarModule, [{
    type: NgModule,
    args: [{
      imports: [Avatar, SharedModule],
      exports: [Avatar, SharedModule]
    }]
  }], null, null);
})();
(function () {
  (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(AvatarModule, {
    imports: [Avatar, SharedModule],
    exports: [Avatar, SharedModule]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { Avatar, AvatarClasses, AvatarModule, AvatarStyle };
