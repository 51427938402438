
export interface Monitor {
  id: number
  group: string,
  httpUrl: string,
  scheduleInterval: string,
  active: boolean,
  status: MonitorStatus,
  monitorStatusCheckedAt: number | null,
  monitorType: Type,
  name: string
}

export enum Type{
  HTTP_MONITOR = 'HTTP Monitor',
  SSL_MONITOR = 'SSL Monitor'
}

export enum MonitorStatus {
  UP="UP",
  DOWN="DOWN"
}

export interface MonitorType {
  name: string
  code: string
}

export interface MonitorPayload {
  label: string
  scheduleInterval: ScheduleIntervals
  url: string
}

export interface HttpMonitorPayload extends MonitorPayload {
  expectedResponse: number
  disableScheduler: boolean
  httpMethod: HttpMethods
}

export enum ScheduleIntervals {
  FIVE_MINUTES = '5_MINUTES',
  TEN_MINUTES = '10_MINUTES',
  FIFTEEN_MINUTES = '15_MINUTES',
  ONE_HOUR = '1_HOUR',
  TWELVE_HOURS = '12_HOURS',
  TWENTY_FOUR_HOURS = '24_HOURS',
}


export enum HttpMethods {
  GET = "GET",
  POST = "POST",
  PATCH = "PATCH",
  PUT = "PUT",
  DELETE = "DELETE",
  OPTIONS = "OPTIONS",
}

export const timeUnitMapping: Record<string, string> = {
  MINUTES: "mins",
  MINUTE: "min",
  HOURS: "hrs",
  HOUR: "hr",
  DAYS: "days",
  DAY: "day",
  MONTHS: "mon",
  MONTH: "mon",
  YEARS: "yrs",
  YEAR: "yr"
};

