import {CommonModule} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {ButtonModule} from 'primeng/button';
import {FloatLabelModule} from 'primeng/floatlabel';
import {MenubarModule} from 'primeng/menubar';
import {DropdownModule} from 'primeng/dropdown';
import {InputTextModule} from 'primeng/inputtext';
import {DataViewModule} from 'primeng/dataview';
import {Monitor, MonitorStatus, Type} from '../../models/entities';
import {MonitorCardComponent} from '../../components/monitor-card/monitor-card.component';
import {SidebarStateService} from '../../services/shared/sidebar/sidebar-state.service';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {MonitorService} from '../../services/monitor.service';
import {Select} from 'primeng/select';

// import signal from '@angular/core'

@Component({
  selector: 'app-monitors',
  standalone: true,
  imports: [CommonModule, MenubarModule, ButtonModule, FloatLabelModule, FormsModule, DropdownModule, InputTextModule, DataViewModule, MonitorCardComponent, Select],
  templateUrl: './monitors.component.html',
  styleUrl: './monitors.component.scss'
})
export class MonitorsComponent implements OnInit {
  sidebarVisible !: boolean;
  private sidebarSubscription !: Subscription;
  searchInputText: any;

  constructor(private sidebarState: SidebarStateService,private router: Router,private monitorService: MonitorService) {
  }

  monitors: Monitor[] = [
    {
      id: 1,
      scheduleInterval: "ONE_MINUTE",
      group: "SOI",
      active: false,
      httpUrl: "http://test-carvia.test.org/soi",
      status: MonitorStatus.DOWN,
      monitorStatusCheckedAt: Date.now(),
      monitorType: Type.HTTP_MONITOR,
      name: 'Dummy'
    },
  ]

  groups: any[] | undefined;
  selectedGroup: any;
  types: any[] | undefined;
  selectedType: any;

  ngOnInit(): void {
    this.sidebarSubscription = this.sidebarState.sidebarVisible$.subscribe(
      visible => {
        this.sidebarVisible = visible;
      }
    )
    this.monitorService.getAll().subscribe(
      data => {
        data.forEach(val => {
          console.log('individual data...', val)
          this.monitors.push(val);
        })
      }
    );
  }

  navigateToCreateMonitor() {
    this.router.navigate(['/monitors/create']);
  }
}
