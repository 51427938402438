import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {Monitor, MonitorStatus} from '../../models/entities';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { ChipModule } from 'primeng/chip';
import {NgClass, NgIf} from '@angular/common';
import {Image} from 'primeng/image';

@Component({
  selector: 'app-monitor-card',
  standalone: true,
  imports: [CardModule, ButtonModule, ChipModule, NgIf, NgClass, Image],
  templateUrl: './monitor-card.component.html',
  styleUrl: './monitor-card.component.scss'
})
export class MonitorCardComponent implements OnChanges{
  @Input() monitor!: Monitor;
  @Input() sidebarVisible!:boolean;

  protected readonly MonitorStatus = MonitorStatus;
  ngOnChanges(changes: SimpleChanges): void {
  }


  card = {
    handle: {
      background: '#EEF2FF'
}
  }
}
