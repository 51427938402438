import {Component, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Button} from 'primeng/button';
import {NgOptimizedImage} from '@angular/common';
import {Ripple} from 'primeng/ripple';
import {RouterLink} from '@angular/router';
import {SidebarStateService} from '../../services/shared/sidebar/sidebar-state.service';
import {Subscription} from 'rxjs';
import { DrawerModule } from 'primeng/drawer';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    DrawerModule,
    Button,
    NgOptimizedImage,
    Ripple,
    RouterLink
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent implements OnInit {
  sidebarVisible : boolean = false;
  private sidebarChangeSubscription!: Subscription;

  constructor(private sidebarState: SidebarStateService) {
  }

  onSidebarHide() {
    this.sidebarVisible = false;
    this.sidebarState.setSidebarVisible(false);
  }

  ngOnInit(): void {
    this.sidebarChangeSubscription = this.sidebarState.sidebarVisible$.subscribe(
      visible => {
        this.sidebarVisible = visible;
      }
    )
  }

  drawer = {
    handle: {
      background: '{indigo.50}'
    },
    colorScheme: {
      light: {
        root: {
          background: '{indigo.50}',
        },
        handle: {
          background: '{indigo.50}',
        }
      },
    }
  }
}
