import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Monitor } from '../models/entities';
import { environment } from '../../environments/environment.dev';

@Injectable({
  providedIn: 'root'
})
export class MonitorService {

  constructor(private httpClient: HttpClient) { }

  public getAll(){
    console.log('getting monitors', environment.baseUri)
    return this.httpClient.get<Monitor[]>(environment.baseUri+'/monitor')
  }
}
